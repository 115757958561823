import * as React from "react"
import ScrollToTop from "../helpers/ScrollToTop";
import ScrollIntoView from "../helpers/ScrollIntoView";
import HomeRestaurant from "./HomeRestaurant";


// markup
const IndexPage = () => {
  return (
    <main>
      {/* <ScrollIntoView>
        <ScrollToTop> */}
          <HomeRestaurant></HomeRestaurant>
        {/* </ScrollToTop>
      </ScrollIntoView> */}
    </main>
  )
}

export default IndexPage
